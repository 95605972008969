import React from 'react';
import './course.css';

export default class Course extends React.Component {
  render() {
    const { sideColor } = this.props;
    const courseArray = this.props.courseArray;
    const courseItems = courseArray.map((courseInfo) =>
      <>
        <div className='line' />
        <div className="courseContentContainer">
          <span className="courseNameContainer">{courseInfo.kcmc}</span>
          <span className="teacherContainer">{courseInfo.teacher}</span>
        </div>
      </>
    );
    if (this.props.courseArray.length === 0) {
      return (
        <div></div>
      )
    } else {
      return (
        <div className="courseContainer"
          style={{
            borderColor: sideColor,
            boxShadow: `10px 13px 20px -23px ${sideColor}`
          }}
        >
          <div className="courseTypeContainer">{this.props.courseType}</div>
          <div>{courseItems}</div>
        </div>
      )
    }
  }
}