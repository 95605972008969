import React from 'react';

import './load.css';

export default class Load extends React.Component {
    render() {
      if(this.props.display) {
        return(
          <div className="load-bg">
            <div className="load-container">
              <div className="loader" />
              <p>加载中, 请稍后...</p>
            </div>
          </div>
        )
      } else {
        return(
          <div />
        )
      }
    }
}