import React, { Component } from 'react';
import './style.css';

export default class CourseStatue extends Component {
  render() {
    const { sum, T_H, S, Z_M, KU } = this.props.courseInfo

    if(sum === 0) {
      return (
        <div className="course-status-container">
          你目前还没有选课哦
        </div>
      )
    } else {
      return (
        <div className="course-status-container">
          <p>
            你当前共有 {sum} 门选课
          </p>
          <p>
            其中，T/H类 {T_H} 门，S类 {S} 门，Z/M类 {Z_M} 门, KU类 {KU} 门
          </p>
        </div>
      )
    }
  }

}
