import React from 'react';
import Course from './components/course/';
import Load from './components/load/';
import CourseStatue from './components/course-status/index.jsx';
import './APP.css';
import { networkModule, Ready } from 'mincu-react'

//课程类型容器的边框及阴影颜色集
const sideColors = ['#ff422dbb', '#fca11ab0', '#35bc1a8f', '#34dbc5c0', ' #cd6cd6da'];

export class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      courseData: '',
      load: true
    }
  }

  componentDidMount() {
    this.getDataAndSet();
  }

  getDataAndSet = async () => {
    try {
      const res = await networkModule.fetch('/apiInside/course/elective_course', {
        baseURL: 'https://api-erlei.ncuos.com/'
      })
      console.log('get data successfully');
      this.setState({
        courseData: res.data.data,
        load: false
      })
    } catch(e) {
      console.log(e)
    }
  }

  render() {
    const courseData = this.state.courseData;
    var courseDataArr = []
    let courseInfo = {
      sum: 0,
      T_H: 0,
      S: 0,
      Z_M: 0,
      KU: 0
    }
    for (let courseType in courseData) {
      let p = {}
      p = {
        courseType,
        courseArray: courseData[courseType]
      }
      courseDataArr.push(p)
      if (courseData[courseType].length !== 0) {
        courseInfo.sum += courseData[courseType].length
        const typeLetter = courseType.trim().split(' ')[1]
        console.log(typeLetter);
        switch (typeLetter) {
          case 'T':
          case 'H':
            console.log('H++')
            courseInfo.T_H += courseData[courseType].length;
            break;
          case 'S':
            console.log('S++')
            courseInfo.S += courseData[courseType].length;
            break;
          case 'Z':
          case 'M':
            console.log('M++')
            courseInfo.Z_M += courseData[courseType].length;
            break;
          case 'KU':
            console.log('KU++')
            courseInfo.KU += courseData[courseType].length;
            break;
          default:
            console.log(`read type error, type letter: ${typeLetter}.`)
        }
      }
    }
    const courseItems = courseDataArr.map((courseInfo, index) =>
      <Course courseType={courseInfo.courseType} courseArray={courseInfo.courseArray} sideColor={sideColors[index]} />
    );
    let courseStatue = null
    if (this.state.load) {
      courseStatue = <div />
    } else {
      courseStatue = <CourseStatue courseInfo={courseInfo} />
    }
    return (
      <Ready>
        <div>
          {courseStatue}
          {courseItems}
          {/* <Course courseType={'test'} courseArray={[{kcmc:'test',teacher:'test'}]} />  */}{/* 用于调试样式 */}
          <Load display={this.state.load} />
        </div>
      </Ready>
    )
  }
}
